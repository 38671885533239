import React from 'react'

const CTA_Button = ({ onClick, text }) => {
    return (
        <div className="text-center-new">
          <button
            style={{
              padding: "10px 16px",
              backgroundColor: "white",
              color: "black",
              border: "2px solid #ccc",
              borderRadius: "45px",
              boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
              transition: "background-color 0.3s, border-color 0.3s",
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = "#ffa737";
              e.target.style.borderColor = "#ffa737";
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "white";
              e.target.style.borderColor = "#ccc";
            }}
            onClick={onClick}
          >
            {text}
          </button>
        </div>
      );
}

export default CTA_Button