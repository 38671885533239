import React from "react";
import { useParams } from "react-router-dom";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import LocationData from "../../data/footer_location.json";
import BreadcrumbForFooterLocations from "../Breadcrumb/BreadcrumbForFooterLocations";
import FormSection from "../formSection.js/formSection";

const FooterLocations = () => {
  const { city } = useParams();

  const pageData = LocationData[city] || {
    content: "<p>Content not found.</p>",
  };
  const { BreadcrumbImage, content } = pageData;

  return (
    <>
      <div style={{ backgroundColor: "#f5f5f5" }}>
        <HeaderOne imageData="/img/aaban-text-logo.png" />
        <BreadcrumbForFooterLocations image={BreadcrumbImage} />
        <div
          style={{
            background:
              "linear-gradient(to right, rgb(95, 218, 255), rgb(75, 231, 13))",
            textAlign: "center",
          }}
        >
          <h3 className="text-center pt-5 pb-5">
            Grow Your Business With Our Effective SEO Services In{" "}
            {city.replace("-", " ").replace(/\b\w/g, (c) => c.toUpperCase())}
          </h3>
        </div>
        <div>
          <div>
            <FormSection
              children={
                <>
                  <div className="col-12 col-lg-5">
                    <img src="/img/footer_locations_meeting.png" className="card-img-top" alt="..."/>
                  </div>
                </>
              }
            />
          </div>
        </div>
        <div className="container pt-5 pb-5">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
      <FooterOne />
    </>
  );
};

export default FooterLocations;
