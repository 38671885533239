import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaPinterest } from "react-icons/fa";
import { BiLogoDribbble } from "react-icons/bi";
import { FaSquareBehance } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { FaSkype } from "react-icons/fa";
import {
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
  XIcon,
} from "react-share";

const BASE_URL =
  "https://my-json-server.typicode.com/themeland/digimax-json-2/themeOneFooterSection";

const FooterOne = () => {
  const [data, setData] = useState({});
  const [footerList_1, setFooterList1] = useState([]);
  const [footerList_2, setFooterList2] = useState([]);
  const [footerList_3, setFooterList3] = useState([]);
  const [iconList, setIconList] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}`)
      .then((res) => {
        setData(res.data);
        setFooterList1(res.data.footerList_1);
        setFooterList2(res.data.footerList_2);
        setFooterList3(res.data.footerList_3);
        setIconList(res.data.iconList);
      })
      .catch((err) => console.log(err));
  }, []);

  const [hovered, setHovered] = useState(null);

  // Function to handle WhatsApp click
  const handleWhatsAppClick = () => {
    const phoneNumber = "7046787233";
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, "_blank");
  };

  // Define all social icons and their respective links
  const socialIcons = [
    { link: "https://www.facebook.com/AabanSolutions/", icon: <FacebookIcon size={23} round={true} /> },
    { link: "https://x.com/AabanSolutions_", icon: <XIcon size={23} round={true} /> },
    { link: "https://www.linkedin.com/company/aaban-solutions/", icon: <LinkedinIcon size={23} round={true} /> },
    { link: "https://in.pinterest.com/aabansolutions313/", icon: <FaPinterest color="#e60023" size={23} /> },
    { link: "#", icon: <BiLogoDribbble size={23} color="#ea4c89"/> },
    { link: "https://www.behance.net/aaban/", icon: <FaSquareBehance size={23} color="#053eff" /> },
    { link: "#", icon: <FaYoutube size={23} color="#e71717" /> },
    { link: "#", icon: <FaSkype size={23} color="#16b6fb" /> },
  ];


  const footerAddressData = [
    {
      country: "USA",
      address: "1747 W Pratt Blvd, 2 Chicago, IL 60626, United States",
      phones: ["+(1) (312) 5985838", "+(1) (872) 278-4146"],
      email: "info@aabansolutions.com",
    }
    // {
    //   country: "USA",
    //   address: "1519 W Warren Blvd, Chicago, IL 60607, USA",
    //   phones: ["+(1) (312) 5985838", "+(1) (872) 278-4146"],
    //   email: "info@aabansolutions.com",
    // },
    // {
    //   country: "India",
    //   address: "1519 W Warren Blvd, Chicago, IL 60607, USA",
    //   phones: ["+(1) (312) 5985838", "+(1) (872) 278-4146"],
    //   email: "info@aabansolutions.com",
    // },
    // {
    //   country: "India",
    //   address: "1519 W Warren Blvd, Chicago, IL 60607, USA",
    //   phones: ["+(1) (312) 5985838", "+(1) (872) 278-4146"],
    //   email: "info@aabansolutions.com",
    // },
  ];

  return (
    <footer className="section footer-area">
      <div className="footer-top" style={{paddingTop: "4rem", paddingBottom: "3rem"}}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="footer-items">
                  <h4 className="footer-title mb-2">About Us</h4>
                  <div>
                    <p style={{ lineHeight: 2 }}>
                      We specialize in providing solutions that help businesses
                      thrive and succeed. Whether you're a small startup or a
                      large corporation, we have the expertise and tools to
                      support your growth journey.
                    </p>
                  </div>
              </div>
              <div className="footer-items">
                <h2 className="footer-title mb-2" style={{ fontSize: "25px" }}>
                  Social community
                </h2>
                <ul
                  className="list-inline pt-2 d-flex flex-wrap"
                  style={{ gap: "15px", width: "80%" }}
                >
                  {socialIcons.map((item, index) => (
                    <li key={index}>
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        onMouseEnter={() => setHovered(index)}
                        onMouseLeave={() => setHovered(null)}
                        style={{
                          display: "inline-block",
                          transform: hovered === index ? "scale(1.3)" : "scale(1)",
                          transition: "transform 0.2s ease-in-out",
                        }}
                      >
                        {item.icon}
                      </a>
                    </li>
                  ))}
                  {/* WhatsApp Icon with click handler */}
                  <li>
                    <div
                      onClick={handleWhatsAppClick}
                      onMouseEnter={() => setHovered("whatsapp")}
                      onMouseLeave={() => setHovered(null)}
                      style={{
                        display: "inline-block",
                        transform: hovered === "whatsapp" ? "scale(1.3)" : "scale(1)",
                        transition: "transform 0.2s ease-in-out",
                        cursor: "pointer",
                      }}
                    >
                      <WhatsappIcon size={23} round={true} />
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-3"> 
              <div className="footer-items">
                <h3 className="footer-title mb-2">Services</h3>
                <ul>
                  <li className="py-2">
                    <a
                      className="text-black-50"
                      href="/digital-marketing/seo-services-usa"
                    >
                      Search Engine Optimization (SEO)
                    </a>
                  </li>
                  <li className="py-2">
                    <a
                      className="text-black-50"
                      href="/digital-marketing/affordable-seo-services-for-small-business"
                    >
                      Small Business SEO
                    </a>
                  </li>
                  <li className="py-2">
                    <a
                      className="text-black-50"
                      href="/digital-marketing/local-seo-services"
                    >
                      Local SEO Services
                    </a>
                  </li>
                  <li className="py-2">
                    <a
                      className="text-black-50"
                      href="/digital-marketing/ecommerce-seo-services"
                    >
                      E-commerce SEO
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-black-50" href="#">
                      Website Development
                    </a>
                  </li>
                  <li className="py-2">
                    <a
                      className="text-black-50"
                      href="/digital-marketing/website-redesign-service"
                    >
                      Website Re-Designing
                    </a>
                  </li>
                  <li className="py-2">
                    <a
                      className="text-black-50"
                      href="/digital-marketing/mobile-app-development"
                    >
                      Application Development
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            
            <div className="col-12 col-sm-6 col-lg-2">
              <div className="footer-items">
                <h3 className="footer-title mb-2">Important Links</h3>
                <ul>
                  <li className="py-2">
                    <a className="text-black-50" href="/aaban-portfolio">
                      Portfolio
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-black-50" href="/blog">
                      Blog
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-black-50" href="/pricing/seo-pricing">
                      Pricing
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-black-50" href="/about-us">
                      About Us
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-black-50" href="/contact">
                      Contact Us
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-black-50" href="/terms-of-service">
                      Terms of Service
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-black-50" href="/privacy-policy">
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-2">
              <div className="footer-items">
                <h3 className="footer-title mb-2">Location</h3>
                <ul>
                  <li className="py-2">
                    <a className="text-black-50" href="/location/los-angeles">
                     Los Angeles
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-black-50" href="/location/san-antonio">
                     San Antonio
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-black-50" href="/location/new-york">
                     New York
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-black-50" href="/location/houston_tx">
                     Houston, Tx
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-black-50" href="/location/chicago">
                     Chicago
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-black-50" href="/location/san-jose">
                     San Jose
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-2">
              
            {footerAddressData.map((item, index) => (
            <div className="footer-items">
              <h2 className="footer-title mb-2" style={{ fontSize: "25px" }}>
                {item.country}
              </h2>
              <ul>
                <li className="py-2">{item.address}</li>
                <li className="my-2">
                  {item.phones.map((phone, i) => (
                    <p className="text-black-50" key={i}>
                      <strong>Phone: </strong>
                      <a>{phone}</a>
                    </p>
                  ))}
                </li>
                <li className="">
                  <strong>Email:</strong> <a>{item.email}</a>
                </li>
              </ul>
            </div>
              ))}
            </div>
          </div>

          {/* <div className="text-black">
            <hr style={{ border: "1px solid #00000038" }} />
          </div> */}
          
          <div className="row">
          {/* {footerAddressData.map((item, index) => (
          <div className="col-12 col-sm-6 col-lg-3" key={index}>
            <div className="footer-items">
              <h2 className="footer-title mb-2" style={{ fontSize: "25px" }}>
                {item.country}
              </h2>
              <ul>
                <li>{item.address}</li>
                <li className="my-2">
                  {item.phones.map((phone, i) => (
                    <p className="text-black-50" key={i}>
                      <strong>Phone: </strong>
                      <a>{phone}</a>
                    </p>
                  ))}
                </li>
                <li>
                  <strong>Email:</strong> <a>{item.email}</a>
                </li>
              </ul>
            </div>
        </div>
      ))} */}
          </div>

        </div>
        {/* <div className="text-center-new pt-4">
            <a
              href="/free-consultation"
              className="btn btn-primary"
            >
              Schedule a free call
            </a>
        </div> */}
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom bg-grey">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* Copyright Area */}
              <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                {/* Copyright Left */}
                <div className="copyright-left">
                  2015 Aaban Solution LLC. All Rights Reserved
                </div>
                {/* Copyright Right */}
                <div className="copyright-right">
                  Made with <i className="fas fa-heart" /> By{" "}
                  <a href="/#">Aaban Solutions</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
