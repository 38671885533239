import React, { useState, useEffect } from "react";

const DisplayLocationBanner = () => {
  const [location, setLocation] = useState("India"); // Default location is India
  const [loading, setLoading] = useState(true);
  const [backgroundPosition, setBackgroundPosition] = useState("center");

  useEffect(() => {
    // Check if geolocation is available
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          // Use OpenStreetMap Nominatim API for reverse geocoding
          const url = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`;

          try {
            const response = await fetch(url);
            const data = await response.json();

            if (data && data.address) {
              // Extract state (region) from the response
              const state = data.address.state || data.address.region || "India";
              setLocation(state); // Set location based on the state
            } else {
              setLocation("India"); // Fallback if no address found
            }
          } catch (error) {
            console.error("Error fetching geolocation:", error);
            setLocation("India"); // Fallback in case of error
          } finally {
            setLoading(false);
          }
        },
        (error) => {
          console.error("Geolocation error:", error);
          setLocation("India"); // Fallback if geolocation fails
          setLoading(false);
        }
      );
    } else {
      setLocation("India"); // Fallback if geolocation is not supported
      setLoading(false);
    }
  }, []);

  // Handle mouse move to dynamically update background gradient position
  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    // Calculate background position as a percentage
    const xPos = (clientX / windowWidth) * 100;
    const yPos = (clientY / windowHeight) * 100;

    setBackgroundPosition(`${xPos}% ${yPos}%`);
  };

  return (
    <section
      style={{
        background: `radial-gradient(circle at ${backgroundPosition}, #af52ed, #8830df)`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "background 0.1s ease", // Smooth transition for the background
      }}
      onMouseMove={handleMouseMove}
    >
      <div className="py-3">
        <h4 className="text-white">
          We at{" "}
          <span style={{ backgroundColor: "black", padding: "4px" }}>
            Aaban
          </span>{" "}
          are determined to make your business successful in{" "}
          <span style={{ backgroundColor: "black", padding: "4px" }}>
            {loading ? "Loading..." : location}
          </span>
          .
        </h4>
      </div>
    </section>
  );
};

export default DisplayLocationBanner;
