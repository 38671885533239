import React, { useEffect, useState } from "react";

import Banner3 from "../components/Banners/banner3";
import HeaderOne from "../components/Header/HeaderOne";
import BreadcrumbThree from "../components/Breadcrumb/BreadcrumbThree";
import FooterOne from "../components/Footer/FooterOne";
import ReviewOne from "../components/Reviews/ReviewOne";
import OurReach from "../components/AboutUs/OurReach";
import UnmatchedExpertise from "../components/AboutUs/UnmatchedExpertise";
import {
  FaMoneyBillWave,
  FaHeartbeat,
  FaLaptopCode,
  FaGraduationCap,
  FaHandsHelping,
  FaBuilding,
  FaHardHat,
  FaPalette,
  FaChartLine,
  FaCheckCircle,
} from "react-icons/fa";

const Aboutus = ({ pageName }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [columns, setColumns] = useState("auto-fit");

  const handleContactClick = (email) => {
    window.location.href = `mailto:${email}`;
  };

  // **** chnaged one (1) ****

  // const containerStyle = {
  //   boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.2)",
  //   padding: "20px",
  //   borderRadius: "3px",
  //   backgroundColor: "#fff",
  //   margin: "0 auto",
  // };

  // const headingStyle = {
  //   fontSize: "24px",
  //   fontWeight: "bold",
  //   marginBottom: "10px",
  //   color: "#333",
  // };

  // const listStyle = {
  //   listStyleType: "none",
  //   paddingLeft: "0",
  //   marginTop: "20px",
  // };

  // const itemStyle = {
  //   fontWeight: "500",
  //   fontSize: "18px",
  //   marginBottom: "10px",
  //   color: "#555",
  //   display: "flex",
  //   alignItems: "center",
  // };

  // const iconStyle = {
  //   marginRight: "10px",
  //   color: "#198fc3",
  //   minWidth: "24px",
  // };

  // ***** changed one (2) *****
  const containerStyle = {
    padding: "40px 20px",
    background: "linear-gradient(135deg, #09599e73, #8830df73)",
    textAlign: "center",
  };

  const headingStyle = {
    fontSize: "36px",
    fontWeight: "bold",
    marginBottom: "30px",
    color: "#fff",
  };

  const itemStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderRadius: "10px",
    backgroundColor: "#fff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s, box-shadow 0.3s",
  };

  const itemHoverStyle = {
    transform: "translateY(-10px)",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
  };

  const iconStyle = {
    fontSize: "32px",
    color: "#198fc3",
    marginBottom: "10px",
  };

  const textStyle = {
    fontSize: "18px",
    fontWeight: "500",
    color: "#555",
  };

  const industries = [
    { icon: <FaHeartbeat />, name: "Healthcare" },
    { icon: <FaLaptopCode />, name: "Information Technology" },
    { icon: <FaGraduationCap />, name: "Education" },
    { icon: <FaHandsHelping />, name: "Non-Profit Organizations" },
    { icon: <FaBuilding />, name: "Real Estate" },
    { icon: <FaHardHat />, name: "Construction" },
    { icon: <FaPalette />, name: "Creative Businesses" },
    { icon: <FaChartLine />, name: "All Businesses" },
  ];

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const updateColumns = () => {
    if (window.innerWidth >= 992) {
      setColumns(4);
    } else {
      setColumns("auto-fit");
    }
  };

  useEffect(() => {
    updateColumns();
    window.addEventListener("resize", updateColumns);
    return () => window.removeEventListener("resize", updateColumns);
  }, []);

  const listStyle = {
    display: "grid",
    gap: "15px",
    padding: "0",
    paddingBottom: "1rem",
    gridTemplateColumns: `repeat(${columns}, minmax(180px, 1fr))`,
  };

  // ***** why shoose aaban ******

  useEffect(() => {
    const revealOnScroll = () => {
      const sections = document.querySelectorAll(".reveal-section");
      sections.forEach((section) => {
        const sectionTop = section.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (sectionTop < windowHeight) {
          section.style.opacity = 1;
          section.style.transform = "translateY(0)";
        } else {
          section.style.opacity = 0;
          section.style.transform = "translateY(50px)";
        }
      });
    };
    window.addEventListener("scroll", revealOnScroll);
    revealOnScroll(); // Initial check on page load
    return () => window.removeEventListener("scroll", revealOnScroll);
  }, []);

  return (
    <>
      <HeaderOne imageData={"/img/aaban-text-logo.png"} />
      <BreadcrumbThree
        path={"About us"}
        about-title={"About us"}
        desc={
          "Best online digital marketing company with complete quality assurance"
        }
        heading={"About us"}
        image={"/img/aboutUs-wall.jpg"}
      />
      <div
        className="about-section-bottom-mergin bg-grey"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <section
          className=" d-flex align-items-center about-10-style"
          style={{ gap: "100px" }}
        >
          <div className="d-flex">
            <h3
              style={{
                fontSize: "200px",
                // fontWeight: "600",
                fontWeight: "400",
                lineHeight: "normal",
                color: "rgb(4 186 243)",
              }}
            >
              [
            </h3>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ gap: "30px" }}
            >
              <h1 className="pl-3" style={{ fontSize: "70px" }}>
                10+
              </h1>
              <div className="pr-3 d-flex flex-column  ">
                <h6>Years</h6>
                <h6>Experience</h6>
              </div>
            </div>
            <h3
              style={{
                fontSize: "200px",
                fontWeight: "400",
                color: "rgb(4 186 243)",
                lineHeight: "normal",
              }}
            >
              ]
            </h3>
          </div>

          <div className="d-flex flex-column" style={{ gap: "10px" }}>
            <p>SINCE 2014</p>
            <div>
              <p style={{ color: "black", fontWeight: "600" }}>
                Truly corporate
              </p>
              <p style={{ color: "black", fontWeight: "600" }}>
                company based in
              </p>
              <p style={{ color: "black", fontWeight: "600" }}> USA & India</p>
            </div>
          </div>

          <div className="about-com-data">
            <p>Aaban Solutions has emerged as the new center of</p>
            <p>excellence in the field of customized software and</p>
            <p>premium web development, providing innovative solutions</p>
            <p>tailored to meet the unique needs of businesses worldwide.</p>
            <p>With a dedicated team of experts and a commitment to</p>
            <p>delivering cutting-edge technologies, we strive to redefine</p>
            <p>industry standards and exceed client expectations.</p>
          </div>
        </section>
      </div>

      {/* <div className="container my-5" style={{ border: "1px solid green" }}>
        <h4>Here’s what sets us apart:</h4>
        <div className="content-grid-about mt-3">
          <div className="content-item-about">
            <p style={{ fontWeight: "bold" }}>Brand Making</p>
            <p>
              Right from implementing the marketing strategies to representing a
              positive reputation of your business online, we know how to
              exhibit you as a BRAND in your domain.{" "}
            </p>
          </div>
          <div className="content-item-about">
            <p style={{ fontWeight: "bold" }}>A Highly Proficient Team</p>
            <p>
              We have a team of experienced professionals taking every possible
              step to take your business to new heights.
            </p>
          </div>
        </div>
        <div className="content-grid-about mt-4">
          <div className="content-item-about">
            <p style={{ fontWeight: "bold" }}>
              Strategic & Cost-Effective Planning
            </p>
            <p>
              We work on agile work processes and next-gen strategies to ensure
              your organization’s growth, minimize the competitor’s strength,
              and meet the well-articulated aims at an affordable price.
            </p>
          </div>
          <div className="content-item-about">
            <p style={{ fontWeight: "bold" }}>Offering High Success Rate</p>
            <p>
              Any business that has taken to the online platform for promotion
              and branding will need a great (and responsive) website followed
              by strong marketing strategies. Aabansolution does exactly that!
              We help your company progress on a high success rate.
            </p>
          </div>
        </div>
      </div> */}

      {/* ***** changed one *****  */}
      <div className="container my-5">
        <h4>Here’s what sets us apart:</h4>
        <div className="content-grid-about mt-3">
          <div className="content-item-about">
            <p style={{ fontWeight: "bold" }}>Brand Making</p>
            <p style={{ fontWeight: "500" }}>
              Right from implementing the marketing strategies to representing a
              positive reputation of your business online, we know how to
              exhibit you as a BRAND in your domain.{" "}
            </p>
          </div>
          <div className="content-item-about">
            <p style={{ fontWeight: "bold" }}>A Highly Proficient Team</p>
            <p style={{ fontWeight: "500" }}>
              We have a team of experienced professionals taking every possible
              step to take your business to new heights.
            </p>
          </div>
        </div>
        <div className="content-grid-about mt-4">
          <div className="content-item-about">
            <p style={{ fontWeight: "bold" }}>
              Strategic & Cost-Effective Planning
            </p>
            <p style={{ fontWeight: "500" }}>
              We work on agile work processes and next-gen strategies to ensure
              your organization’s growth, minimize the competitor’s strength,
              and meet the well-articulated aims at an affordable price.
            </p>
          </div>
          <div className="content-item-about">
            <p style={{ fontWeight: "bold" }}>Offering High Success Rate</p>
            <p style={{ fontWeight: "500" }}>
              Any business that has taken to the online platform for promotion
              and branding will need a great (and responsive) website followed
              by strong marketing strategies. Aabansolution does exactly that!
              We help your company progress on a high success rate.
            </p>
          </div>
        </div>
      </div>

      {/* <div className="container my-5">
        <h4>Industries we serve:</h4>

        <ul className="mt-2">
          <li>Finance</li>
          <li>Healthcare</li>
          <li>Information Technology</li>
          <li>Education</li>
          <li>Non-Profit Organizations</li>
          <li>Real Estate</li>
          <li>Construction</li>
          <li>Creative Businesses</li>
          <li>
            All in all, we are open to all business types ranging from emerging
            businesses to Fortune 500 brands.
          </li>
        </ul>
      </div> */}

      {/* ***** changed one (2) ***** */}
      <div style={containerStyle}>
        <h4 style={headingStyle}>Industries we serve:</h4>
        <ul style={listStyle}>
          {industries.map((industry, index) => (
            <li
              key={index}
              style={
                hoveredIndex === index
                  ? { ...itemStyle, ...itemHoverStyle }
                  : itemStyle
              }
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <div style={iconStyle}>{industry.icon}</div>
              <div style={textStyle}>{industry.name}</div>
            </li>
          ))}
        </ul>
      </div>

      <div className="container my-5">
        <h4 className="mt-2">
          The Reason Behind Aabansolutions Strong Foundation Experienced Subject
          Matter Experts
        </h4>
        <p style={{ fontWeight: "500" }}>
          Aabansolution is a team of Subject Matter Experts having experience of
          working with many IT giants like Google and Wipro. The team comes from
          a strong IT background knowledge to ensure the best for his clients.
          Since always, Aabansolution has stepped ahead with a progressive
          approach and knowledgeable insights to help clients get the best.
          Resulting, today Aabansolution is entitled as the B2B Leader by
          Clutch.
        </p>
      </div>
      <div className="container my-5">
        <h4 className="mt-2">Our Strength</h4>
        <p className="mt-2" style={{ fontWeight: "500" }}>
          Aabansolution sees its workforce as the major pillar of success,
          turning impossibilities into possibilities. We are bestowed with a
          team of experienced and well-resourced professionals who work with a
          zeal of presenting perfection and business developing results on our
          clients table.
        </p>
        <p className="my-2" style={{ fontWeight: "500" }}>
          To have a word with our professionals and discuss your business
          requirements, step ahead, and share your interests. Our team assures
          to get back to you the same day.
        </p>
        <p style={{ fontWeight: "500" }}>
          Aabansolution Treating Your Business Success As Our Own!
        </p>
      </div>
      {/* <div className="container my-5">
        <h4>Why Choose Aabansolution?</h4>
        <div className="my-2">
          <strong>Happy 2 Help:</strong> Everything we do is about making your
          marketing easier. That's why our motto of Aabansolution Is Easy To
          Work With, Happy 2 Help, permeates our team, our work, and our
          culture. In every interaction, we strive to be friendly, helpful,
          attentive, and valuable partners for your business.
        </div>
        <div className="my-2">
          <strong>Team of Experts:</strong> Marketing is constantly evolving,
          and no single person can possibly know everything. We cultivate a
          whole team of experts who love marketing in general but specialize in
          their unique fields. That way, Aabansolution can bring the widest
          breadth of marketing expertise to your business.
        </div>
        <div className="my-2">
          <strong>Proven Process:</strong> Great marketing doesn't happen by
          accident it starts with a plan. Aabansolution kicks off every project
          by getting to know your business in-depth and developing a solid
          strategy, customized for you. With our proven 5-step process, we put
          your business on the path to success.
        </div>
        <div>
          <strong>We Get Results:</strong> We believe that marketing should be
          an investment, not an expense. That's why Aabansolution focuses on
          your unique goals and getting the results that matter for your
          business. We'll track and report on all our efforts along the way, so
          you can see the value of your marketing.
        </div>
      </div> */}
      {/* *****  changed one (1)*****  */}
      {/* <div
        className="container my-5"
        style={{
          borderRadius: "3px",
          padding: "20px",
        }}
      >
        <h4
          style={{
            color: "#fff",
            // textTransform: "uppercase",
            marginBottom: "20px",
            fontSize: "24px",
            background: "linear-gradient(-45deg, #af52ed 0%, #8830df 100%)",
            padding: "10px",
            borderRadius: "5px",
            textAlign: "center",
            boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.2)",
          }}
        >
          Why Choose Aabansolution?
        </h4>
        <div
          className="my-2"
          style={{ fontSize: "18px", lineHeight: "1.6", color: "#333" }}
        >
          <FaCheckCircle style={{ color: "#af52ed", marginRight: "10px" }} />
          <strong style={{ color: "#af52ed" }}>Happy 2 Help:</strong> Everything
          we do is about making your marketing easier. That's why our motto of
          Aabansolution Is Easy To Work With, Happy 2 Help, permeates our team,
          our work, and our culture. In every interaction, we strive to be
          friendly, helpful, attentive, and valuable partners for your business.
        </div>
        <div
          className="my-2"
          style={{ fontSize: "18px", lineHeight: "1.6", color: "#333" }}
        >
          <FaCheckCircle style={{ color: "#af52ed", marginRight: "10px" }} />
          <strong style={{ color: "#af52ed" }}>Team of Experts:</strong>{" "}
          Marketing is constantly evolving, and no single person can possibly
          know everything. We cultivate a whole team of experts who love
          marketing in general but specialize in their unique fields. That way,
          Aabansolution can bring the widest breadth of marketing expertise to
          your business.
        </div>
        <div
          className="my-2"
          style={{ fontSize: "18px", lineHeight: "1.6", color: "#333" }}
        >
          <FaCheckCircle style={{ color: "#af52ed", marginRight: "10px" }} />
          <strong style={{ color: "#af52ed" }}>Proven Process:</strong> Great
          marketing doesn't happen by accident it starts with a plan.
          Aabansolution kicks off every project by getting to know your business
          in-depth and developing a solid strategy, customized for you. With our
          proven 5-step process, we put your business on the path to success.
        </div>
        <div style={{ fontSize: "18px", lineHeight: "1.6", color: "#333" }}>
          <FaCheckCircle style={{ color: "#af52ed", marginRight: "10px" }} />
          <strong style={{ color: "#af52ed" }}>We Get Results:</strong> We
          believe that marketing should be an investment, not an expense. That's
          why Aabansolution focuses on your unique goals and getting the results
          that matter for your business. We'll track and report on all our
          efforts along the way, so you can see the value of your marketing.
        </div>
      </div> */}
      <div
        className="container-fluid py-5"
        style={{
          background: "linear-gradient(135deg, #09599e, #8830df)",
          color: "#fff",
          borderRadius: "10px",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div
          className="parallax-bg"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: "url('/img/dotted.png')",
            backgroundAttachment: "fixed",
            backgroundPosition: "center",
            backgroundSize: "cover",
            opacity: 0.5,
          }}
        ></div>
        <div className="container">
          <h2 className="text-center text-white mb-1">
            Why Choose Aabansolution?
          </h2>
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div
                className="card border-light mb-3 reveal-section"
                style={{
                  opacity: 0,
                  transform: "translateY(50px)",
                  transition: "opacity 0.5s ease, transform 0.5s ease",
                }}
              >
                <div className="card-body">
                  <h5 className="card-title mb-3">
                    <FaCheckCircle className="check-icon" />
                    Happy 2 Help:
                  </h5>
                  <p className="card-text">
                    Everything we do is about making your marketing easier.
                    That's why our motto of Aabansolution Is Easy To Work With,
                    Happy 2 Help, permeates our team, our work, and our culture.
                    In every interaction, we strive to be friendly, helpful,
                    attentive, and valuable partners for your business.
                  </p>
                </div>
              </div>
              <div
                className="card border-light mb-3 reveal-section"
                style={{
                  opacity: 0,
                  transform: "translateY(50px)",
                  transition: "opacity 0.5s ease, transform 0.5s ease",
                }}
              >
                <div className="card-body">
                  <h5 className="card-title mb-3">
                    <FaCheckCircle className="check-icon" />
                    Proven Process:
                  </h5>
                  <p className="card-text">
                    Great marketing doesn't happen by accident it starts with a
                    plan. Aabansolution kicks off every project by getting to
                    know your business in-depth and developing a solid strategy,
                    customized for you. With our proven 5-step process, we put
                    your business on the path to success.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="card border-light mb-3 reveal-section"
                style={{
                  opacity: 0,
                  transform: "translateY(50px)",
                  transition: "opacity 0.5s ease, transform 0.5s ease",
                }}
              >
                <div className="card-body">
                  <h5 className="card-title mb-3">
                    <FaCheckCircle className="check-icon" />
                    Team of Experts:
                  </h5>
                  <p className="card-text">
                    Marketing is constantly evolving, and no single person can
                    possibly know everything. We cultivate a whole team of
                    experts who love marketing in general but specialize in
                    their unique fields. That way, Aabansolution can bring the
                    widest breadth of marketing expertise to your business.
                  </p>
                </div>
              </div>
              <div
                className="card border-light mb-3 reveal-section"
                style={{
                  opacity: 0,
                  transform: "translateY(50px)",
                  transition: "opacity 0.5s ease, transform 0.5s ease",
                }}
              >
                <div className="card-body">
                  <h5 className="card-title mb-3">
                    <FaCheckCircle className="check-icon" />
                    We Get Results:
                  </h5>
                  <p className="card-text">
                    We believe that marketing should be an investment, not an
                    expense. That's why Aabansolution focuses on your unique
                    goals and getting the results that matter for your business.
                    We'll track and report on all our efforts along the way, so
                    you can see the value of your marketing.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <section className="bg-grey">
        <div className="mx-5 py-4 ">
          <h3 style={{ textAlign: "center" }}>Our Team</h3>

          <div className="d-flex justify-content-center align-items-center text-center">
            <div
              style={{
                borderBottom: "3px solid #faa738",
                width: "5%",
                height: "20px",
              }}
            ></div>
          </div>

          <div className="row">
            <div className="about-column about-column-style">
              <div className="about-card py-2">
                <img
                  src="/img/Profile-About.jpg"
                  alt="Jane"
                  style={{ width: "100%", height: "200px" }}
                />
                <div className="about-container">
                  <h4>Jane Doe</h4>
                  <p className="about-title">CEO & Founder</p>
                  <p className="my-2">
                    Some text that describes me lorem ipsum ipsum lorem.
                  </p>
                  <p className="about-p-color">jane@example.com</p>
                  <p className="mt-2 about-p-color">
                    <button
                      className="about-button"
                      onClick={() => handleContactClick("jane@example.com")}
                    >
                      Contact
                    </button>
                  </p>
                </div>
              </div>
            </div>

            <div className="about-column about-column-style">
              <div className="about-card py-2">
                <img
                  src="/img/Profile-About.jpg"
                  alt="Mike"
                  style={{ width: "100%", height: "200px" }}
                />
                <div className="about-container">
                  <h4>Talha Sayyed (Aman)</h4>
                  <p className="about-title">Vice President of Sales</p>
                  <p className="my-2 about-p-color">
                    Revenue Growth || Digital Marketing || SEO || Website
                    Development || App Development || International Sales ||
                    Training and Team building.{" "}
                  </p>
                  <p className="about-p-color">aman@aabansolution.com</p>
                  <p className="mt-2 about-p-color">
                    <button
                      className="about-button"
                      onClick={() =>
                        handleContactClick("aman@aabansolution.com")
                      }
                    >
                      Contact
                    </button>
                  </p>
                </div>
              </div>
            </div>

            <div className="about-column about-column-style">
              <div className="about-card py-2">
                <img
                  src="/img/Profile-About.jpg"
                  alt="John"
                  style={{ width: "100%", height: "200px" }}
                />
                <div className="about-container">
                  <h4>John Doe</h4>
                  <p className="about-title">Designer</p>
                  <p className="my-2 about-p-color">
                    Some text that describes me lorem ipsum ipsum lorem.
                  </p>
                  <p className="about-p-color">john@example.com</p>
                  <p className="mt-2 about-p-color">
                    <button
                      className="about-button"
                      onClick={() => handleContactClick("john@example.com")}
                    >
                      Contact
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section
        className="section"
        style={{ padding: "50px 0", backgroundColor: "#f8f9fa" }}
      >
        <div className="about-container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-7 order-lg-2">
              <h3>The History Behind Our International SEO Company</h3>
              <p className="pt-2" style={{ fontWeight: "500" }}>
                Our professionals perform the job of promoting your business
                online. We take a custom approach with each client to create a
                detailed strategy with the appropriate digital marketing
                services that will deliver results for any online marketing
                campaign. There are different search engines available on the
                internet. We integrate certain specialized strategies like SEO
                for better visibility.
                <b>best SEO Company</b>, you will never have to face any major
                obstacle.
              </p>
            </div>
            <div className="col-lg-5  d-flex align-items-center justify-content-center order-lg-1">
              <img
                width="450"
                src="/img/pexels-fauxels-3184291.jpg"
                alt="SEO"
              />
            </div>
          </div>
        </div>
      </section>
      <Banner3 />
      <OurReach pageName={pageName} />
      <UnmatchedExpertise pageName={pageName} />
      <ReviewOne />
      <FooterOne />
    </>
  );
};

export default Aboutus;
