import React from "react";
import { ReactComponent as RaceIcon } from "./race.svg"; // Import the SVG as a component

const WorkProcess = () => {
  return (
    <div
      style={{
        width: "100%",
        backgroundImage: "url('/img/dotted.png')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        border: "1px solid transparent",
        
      }}
    >
      <div className="">
        <div className="text-center-new ">
          <h2 style={{padding: "20px" }}>Our Working Process</h2>
        </div>
        <div className="text-center-new pt-2 pb-4">
          {/* Responsive SVG */}
          <div className="svg-container">
            <RaceIcon className="responsive-svg" />
          </div>
        </div>
      </div>

      <style>
        {`
          /* General container styles */
          .svg-container {
            display: flex;
            justify-content: center;
            align-items: center;
          }

            .responsive-svg {
              width: 100%; 
              height: auto;
            }

         
          /* Additional responsiveness for smaller devices */
          @media (max-width: 768px) {
            .svg-container {
              padding: 20px;
            }
          }

          /* Mobile view adjustments (max-width: 425px) */
          @media (max-width: 425px) {

             .responsive-svg {
              max-width: 350px; /* Smaller SVG size for better mobile fit */
              height: auto;
            }

            .text-center-new h2 {
              font-size: 30px; /* Smaller font size for mobile view */
            }
          }
        `}
      </style>
    </div>
  );
};

export default WorkProcess;
