import React, { useEffect } from "react";

const GoogleReviewsWidget = () => {
  useEffect(() => {
    // Dynamically load the Elfsight platform script
    const script = document.createElement("script");
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
      <div
        className="elfsight-app-2f236cc2-f712-4428-a203-0b151b18b09d"
        data-elfsight-app-lazy
      ></div>
  )
};

export default GoogleReviewsWidget;
