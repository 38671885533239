import React, { useEffect, useState } from 'react';
import ReviewOne from '../Reviews/ReviewOne';
import BreadcrumbFour from '../Breadcrumb/BreadcrumbFour';
import FooterOne from '../Footer/FooterOne';
import HeaderOne from '../Header/HeaderOne';
import data from "../../data/db.json";

const Blog = () => {
    const { BlogsCards } = data["blog"];
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  
    const getGridTemplateColumns = () => {
      if (windowWidth >= 1900) {
        return "repeat(5, 1fr)";
      }
      if (windowWidth >= 1350) {
        return "repeat(3, 1fr)";
      }
      if (windowWidth >= 768) {
        return "repeat(2, 1fr)";
      }
      return "1fr";
    };

  return (
    <>
      <HeaderOne imageData="/img/aaban-text-logo.png" />
      <BreadcrumbFour path="Blog" title="Blog" />
      
      <div
        className="px-lg-5 px-sm-0 pt-5 pb-5"
        style={{
          display: "grid",
          gridTemplateColumns: getGridTemplateColumns(),
          gap: "20px",
          backgroundColor: "#f5f5f5",
        }}
      >
        {BlogsCards[1]?.list.map((item, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <div className="card" style={{ borderTop: "10px solid #25ABFC", padding: "15px" }}>
              <img src={`/img/${item?.image}`} alt={item?.heading} style={{ width: "100%" }} />
              <div className="card-body align-items-center shadow">
                <h4 style={{ fontWeight: "bold" }}>{item?.heading}</h4>
                <p>{item?.blog}</p>
                <p><strong>Author:</strong> {item?.author}</p>
                <a 
                  href={`/blog/${item?.redirect}`} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  style={{
                    display: "inline-block",
                    padding: "8px 15px",
                    backgroundColor: "#25ABFC",
                    color: "#fff",
                    textDecoration: "none",
                    borderRadius: "5px",
                    marginTop: "10px",
                    fontWeight: "bold"
                  }}
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
      
      <ReviewOne />
      <FooterOne />
    </>
  );
};

export default Blog;
