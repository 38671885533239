import React, { useState, useEffect } from "react";

const initData = {
  heading: "Tech-Enabled Result-Driven Digital Marketing Agency",
  content:
    "We make sure your money is well spent and the campaigns elevate your brand to the next level.",
  btnText_1: "Start a Project",
  btnText_2: "Get Your Free Proposal",
};

const HeroOne = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    setData(initData);
  }, []);

  return (
    <section
      id="home"
      className="section welcome-area bg-overlay overflow-hidden d-flex align-items-center"
    >
      <div className="video-background">
        <video autoPlay loop muted className="video-bg">
          <source src="/img/backVideo1.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="container">
        <div
          className="row align-items-center"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            className="col-12 col-md-7"
            style={{
              display: "flex",
              justifyContent: "center",
              alignSelf: "center",
            }}
          >
            <div className="welcome-intro">
              {/* Heading with letter animation */}
              <h1 className="text-white heading-animation">
                {data.heading &&
                  data.heading.split("").map((char, index) => (
                    <span
                      key={index}
                      style={{
                        display: "inline-block",
                        animation: `letterGrow 0.5s ease-in-out ${index * 0.1}s forwards`,
                        transform: "scale(0.8)", // Initial size before animation
                      }}
                    >
                      {char}
                    </span>
                  ))}
              </h1>
              <p className="text-white my-4">{data.content}</p>
              <div className="button-group">
                <a
                  href="/free-consultation"
                  className="btn btn-bordered-white mb-2"
                >
                  {data.btnText_1}
                </a>
                <a
                  href="/contact"
                  className="btn btn-bordered-white d-sm-inline-block"
                >
                  {data.btnText_2}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CSS for the animation */}
      <style>{`
        @keyframes letterGrow {
          0% {
            transform: scale(0.8);
            opacity: 0;
          }
          100% {
            transform: scale(1);
            opacity: 1;
          }
        }
      `}</style>
    </section>
  );
};

export default HeroOne;
