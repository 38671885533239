import React, { useState } from "react";
import HeaderOne from "../Header/HeaderOne";
import BreadcrumbThree from "../Breadcrumb/BreadcrumbThree";
import FooterOne from "../Footer/FooterOne";
import { FaPinterest } from "react-icons/fa";
import { BiLogoDribbble } from "react-icons/bi";
import { FaSquareBehance } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { FaSkype } from "react-icons/fa";
import { FacebookIcon, LinkedinIcon, WhatsappIcon, XIcon } from "react-share";

const TermsOfService = () => {
  const [hovered, setHovered] = useState(null);

  const socialIcons = [
    {
      link: "https://www.facebook.com/AabanSolutions/",
      icon: <FacebookIcon size={23} round={true} />,
    },
    {
      link: "https://x.com/AabanSolutions_",
      icon: <XIcon size={23} round={true} />,
    },
    {
      link: "https://www.linkedin.com/company/aaban-solutions/",
      icon: <LinkedinIcon size={23} round={true} />,
    },
    {
      link: "https://in.pinterest.com/aabansolutions313/",
      icon: <FaPinterest color="#e60023" size={23} />,
    },
    { link: "#", icon: <BiLogoDribbble size={23} color="#ea4c89" /> },
    {
      link: "https://www.behance.net/aaban/",
      icon: <FaSquareBehance size={23} color="#053eff" />,
    },
    { link: "#", icon: <FaYoutube size={23} color="#e71717" /> },
    { link: "#", icon: <FaSkype size={23} color="#16b6fb" /> },
  ];

  return (
    <>
      <HeaderOne imageData={"/img/aaban-text-logo.png"} />
      <BreadcrumbThree
        path={"TERMS OF SERVICE"}
        title={"TERMS OF SERVICE"}
        image={"/img/pexels-pixabay-258160.jpg"}
      />

      <div style={{ borderBottom: "1px solid grey" }}>
        <section className="container my-5">
          <div className="my-2">
            <h3 className="mt-4">Terms Of Service</h3>
            <p
              className="mt-3"
              style={{
                fontSize: "20px",
                lineHeight: "32px",
                fontWeight: 600,
                color: "black",
              }}
            >
              Welcome to Aaban Solutions!
            </p>
            <p className="mt-3">
              Please read these terms of service carefully before using our
              software development services operated by Aaban Solutions.
            </p>
            <p className="mt-3">
              Your access to and use of our services is conditioned on your
              acceptance of and compliance with these terms. These terms apply
              to all visitors, users, and others who access or use our software
              development services.
            </p>
            <p className="mt-3">
              Using this website, you agree to be bound by all of the statements
              set forth along with the terms stated in “Terms of Service” and
              “Privacy Policy.”
            </p>
            <p className="mt-3">
              By accessing or using our services, you agree to be bound by these
              terms. If you disagree with any part of the terms, then you may
              not access our services.
            </p>
          </div>

          <div className="my-2">
            <h4 className="mt-4">1. Services</h4>
            <p className="mt-3">
              Aaban Solutions provides software development services, including
              but not limited to, custom software development, web development,
              mobile application development, and related consulting services.
            </p>
          </div>

          <div className="my-2">
            <h4 className="mt-4">2. Client Responsibilities</h4>
            <p className="mt-3">
              Clients engaging with Aaban Solutions for software development
              services are responsible for providing accurate and timely
              information necessary for project completion. Clients agree to
              cooperate with Aaban Solutions throughout the development process,
              providing feedback and approvals as needed.
            </p>
          </div>

          <div className="my-2">
            <h4 className="mt-4">3. Intellectual Property</h4>
            <p className="mt-3">
              Any software code, designs, or other intellectual property
              developed by Aaban Solutions during the course of a project will
              be the exclusive property of the client upon full payment. Aaban
              Solutions retains the right to showcase completed work in its
              portfolio unless otherwise agreed upon in writing.
            </p>
          </div>

          <div className="my-2">
            <h4 className="mt-4">4. Payment and Invoicing</h4>
            <p className="mt-3">
              Clients agree to pay Aaban Solutions in accordance with the
              agreed-upon payment terms. Failure to make timely payments may
              result in project delays or termination.
            </p>
          </div>

          <div className="my-2">
            <h4 className="mt-4">5. Confidentiality</h4>
            <p className="mt-3">
              Aaban Solutions agrees to keep all client information confidential
              and not to disclose or use such information for any purpose other
              than for the provision of services. Clients also agree to keep
              confidential any proprietary information shared by Aaban
              Solutions.
            </p>
          </div>

          <div className="my-2">
            <h4 className="mt-4">5. Confidentiality</h4>
            <p className="mt-3">
              Aaban Solutions agrees to keep all client information confidential
              and not to disclose or use such information for any purpose other
              than for the provision of services. Clients also agree to keep
              confidential any proprietary information shared by Aaban
              Solutions.
            </p>
          </div>

          <div className="my-2">
            <h4 className="mt-4">6. Termination of Services</h4>
            <p className="mt-3">
              Aaban Solutions reserves the right to terminate services for any
              client who breaches these terms or engages in behavior that
              hinders the successful completion of a project.
            </p>
          </div>

          <div className="my-2">
            <h4 className="mt-4">7. Limitation of Liability</h4>
            <p className="mt-3">
              Aaban Solutions shall not be liable for any indirect, special,
              incidental, consequential, or punitive damages, including but not
              limited to loss of profits, data, or business interruption,
              arising out of or in connection with our services.
            </p>
          </div>

          <div className="my-2">
            <h4 className="mt-4">8. Changes to Terms</h4>
            <p className="mt-3">
              We reserve the right to modify or replace these terms at any time.
              Clients will be notified of any material changes with reasonable
              notice.
            </p>
          </div>

          <div className="my-2">
            <h4 className="mt-4">Contact Information</h4>
            <p className="mt-3">
              If you have any questions about these terms, please contact us at
              info@aabansolutions.com.
            </p>
            <p className="mt-3">
              Thank you for choosing Aaban Solutions for your software
              development needs!
            </p>
          </div>

          <div className="my-4" style={{ paddingTop: "30px" }}>
            <h4 className="mt-4">Are you ready to work with us?</h4>
            <p className="mt-3">Connect with social media</p>
            <ul
              className="list-inline pt-2 d-flex flex-wrap"
              style={{ gap: "15px", width: "80%" }}
            >
              {socialIcons.map((item, index) => (
                <li key={index}>
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    onMouseEnter={() => setHovered(index)}
                    onMouseLeave={() => setHovered(null)}
                    style={{
                      display: "inline-block",
                      transform: hovered === index ? "scale(1.3)" : "scale(1)",
                      transition: "transform 0.2s ease-in-out",
                    }}
                  >
                    {item.icon}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </section>
      </div>
      <FooterOne />
    </>
  );
};

export default TermsOfService;
