import React, { useRef } from 'react';
import MyRouts from './routers/routes';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';


// importing MyRouts where we located all of our theme

function App() {
  const tawkMessengerRef = useRef();

  return (
    <div>
      <MyRouts />

        <TawkMessengerReact
        propertyId="619dee086bb0760a49441927"
        widgetId="1ijs68jva"
        useRef={tawkMessengerRef}/>
    </div>
  );
}

export default App;