import React, { useState, useRef, useEffect } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import jsonData from "../../data/faq.json";

const FAQAccordion = () => {
  const questionData = jsonData.faqData;
  const [visibleAnswerIndex, setVisibleAnswerIndex] = useState(null);
  const [maxHeight, setMaxHeight] = useState({});
  const answerRefs = useRef([]);

  const toggleAnswerVisibility = (index) => {
    setVisibleAnswerIndex(index === visibleAnswerIndex ? null : index);
  };

  useEffect(() => {
    if (visibleAnswerIndex !== null) {
      const height = answerRefs.current[visibleAnswerIndex]?.scrollHeight;
      setMaxHeight((prevState) => ({
        ...prevState,
        [visibleAnswerIndex]: height,
      }));
    }
  }, [visibleAnswerIndex]);

  return (
    <div style={{ backgroundColor: "#f5f5f5" }}>
      <section className="container pt-5 pb-5">
        <h2 className="text-center mb-2" style={{ color: "#333" }}>
          Frequently Asked Questions
        </h2>
        {questionData.map((item, index) => {
          const isActive = visibleAnswerIndex === index;
          return (
            <div key={index} style={{ marginBottom: "10px" }}>
              <div
                className="d-flex align-items-center p-3 faq-question"
                style={{
                  border: `1px solid ${isActive ? "#faa738" : "#eeeeee"}`,
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  backgroundColor: isActive ? "#faa738" : "#eeeeee",
                  color: isActive ? "#fff" : "#444",
                  fontWeight: "900",
                  lineHeight: "28px",
                  gap: "10px",
                  cursor: "pointer",
                  transition: "background-color 0.3s, color 0.3s, border 0.3s",
                }}
                onClick={() => toggleAnswerVisibility(index)}
              >
                {isActive ? <FaMinus /> : <FaPlus />}
                <p style={{ margin: 0 }}><strong>{item.question}</strong></p>
              </div>
              <div
                ref={(el) => (answerRefs.current[index] = el)}
                style={{
                  maxHeight: isActive ? `${maxHeight[index]}px` : "0",
                  overflow: "hidden",
                  transition: "max-height 0.3s ease-out",
                  border: "1px solid #faa738",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                  backgroundColor: "#fff",
                }}
              >
                <div className="d-flex align-items-center p-3">
                  <p style={{ margin: 0, color: "#444" }}>{item.answer}</p>
                </div>
              </div>
            </div>
          );
        })}
      </section>
    </div>
  );
};

export default FAQAccordion;
