import React, { useEffect, useState } from "react";
import ModalMenu from "../Modal/ModalMenu";
import { navigationMenu } from "../../data/navigationMenu";
import { FaPhoneAlt, FaPinterest, FaEnvelope, FaMobileAlt  } from "react-icons/fa";
import { BiLogoDribbble } from "react-icons/bi";
import { FaSquareBehance } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { FaSkype } from "react-icons/fa";
import { FacebookIcon, LinkedinIcon, WhatsappIcon, XIcon } from "react-share";
import { Link } from "react-router-dom";

const HeaderOne = (props) => {
  const { backgroundColor = "", imageData = "", style = {} } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [hovered, setHovered] = useState(null);

  const handleWhatsAppClick = () => {
    const phoneNumber = "7046787233";
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, "_blank");
  };

  const socialIcons = [
    {
      link: "https://www.facebook.com/AabanSolutions/",
      icon: <FacebookIcon size={23} round={true} />,
    },
    {
      link: "https://x.com/AabanSolutions_",
      icon: <XIcon size={23} round={true} />,
    },
    {
      link: "https://www.linkedin.com/company/aaban-solutions/",
      icon: <LinkedinIcon size={23} round={true} />,
    },
    {
      link: "https://in.pinterest.com/aabansolutions313/",
      icon: <FaPinterest color="#e60023" size={23} />,
    },
    { link: "#", icon: <BiLogoDribbble size={23} color="#ea4c89" /> },
    {
      link: "https://www.behance.net/aaban/",
      icon: <FaSquareBehance size={23} color="#053eff" />,
    },
    { link: "#", icon: <FaYoutube size={23} color="#e71717" /> },
    { link: "#", icon: <FaSkype size={23} color="#16b6fb" /> },
  ];

  return (
    <header>
      {/* Navbar */}
      <div
        className="container-fluid py-2 px-3"
        style={{
          transition: "background 0.2s",
          background: "linear-gradient(#2daeb3, #8c3ddf)",
          color: "white",
          fontWeight: "bold",
        }}
      >
        <div className="row align-items-center justify-content-between">
          {/* Call-to-action section */}
          <div className="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start">
            <a
              href="/free-consultation"
              className="text-white text-decoration-none d-flex align-items-center"
            >
              Schedule a free call
              <FaPhoneAlt
                className="ml-3"
                style={{
                  color: "red",
                  fontSize: "1.2rem",
                  animation: "ring 1.5s infinite",
                }}
              />
            </a>
          </div>

          {/* Social Icons Section */}
          <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end mt-2 mt-md-0">
            <ul
              className="d-flex align-items-center list-unstyled m-0"
              style={{ gap: "15px" }}
            >
              {socialIcons.map((item, index) => (
                <li
                  key={index}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    onMouseEnter={() => setHovered(index)}
                    onMouseLeave={() => setHovered(null)}
                    style={{
                      display: "inline-block",
                      transform: hovered === index ? "scale(1.2)" : "scale(1)",
                      transition: "transform 0.2s ease-in-out",
                    }}
                  >
                    {item.icon}
                  </a>
                </li>
              ))}
              {/* WhatsApp Icon */}
              <li style={{ display: "flex", alignItems: "center" }}>
                <div
                  onClick={handleWhatsAppClick}
                  onMouseEnter={() => setHovered("whatsapp")}
                  onMouseLeave={() => setHovered(null)}
                  style={{
                    display: "inline-block",
                    transform:
                      hovered === "whatsapp" ? "scale(1.2)" : "scale(1)",
                    transition: "transform 0.2s ease-in-out",
                    cursor: "pointer",
                  }}
                >
                  <WhatsappIcon size={26} round={true} />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        className="d-flex flex-column flex-md-row align-items-center justify-content-around p-1 bg-light text-dark py-2 px-3"
        style={{
          borderRadius: "8px",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Logo Section */}
        <Link to="/">
          <img
            src="/img/imgpsh_fullsize_anim.png"
            alt="brand-logo"
            style={{ height: 45, width: 150, cursor: "pointer" }}
          />
        </Link>

        {/* Contact Details Section */}
        <div
          className="d-flex flex-column flex-md-row align-items-center justify-content-center text-center-new text-md-start mt-2 mt-md-0"
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            color: "#333",
            gap: "20px",
          }}
        >
          <div
            className="d-flex align-items-center"
            style={{ cursor: "pointer", transition: "color 0.3s ease-in-out", gap: "14px"}}
            onMouseEnter={(e) => (e.currentTarget.style.color = "#d44638")}
            onMouseLeave={(e) => (e.currentTarget.style.color = "#333")}
          >
            <FaEnvelope color="#d44638" size={20} />
            <span>info@aabansolutions.com</span>
          </div>
          <span className="d-none d-md-inline">|</span>

          <div
            className="d-flex align-items-center gap-2"
            style={{ cursor: "pointer", transition: "color 0.3s ease-in-out", gap: "14px" }}
            onMouseEnter={(e) => (e.currentTarget.style.color = "#008000")}
            onMouseLeave={(e) => (e.currentTarget.style.color = "#333")}
          >
            <FaPhoneAlt color="#008000" size={20} />
            <span>+(1) (312) 598-5838</span>
          </div>
          <span className="d-none d-md-inline">|</span>

          <div
            className="d-flex align-items-center gap-2"
            style={{ cursor: "pointer", transition: "color 0.3s ease-in-out", gap: "14px" }}
            onMouseEnter={(e) => (e.currentTarget.style.color = "#008000")}
            onMouseLeave={(e) => (e.currentTarget.style.color = "#333")}
          >
            <FaMobileAlt color="#008000" size={20} />
            <span>+(1) (872) 278-4146</span>
          </div>
        </div>
      </div>

      <nav
        data-aos="zoom-out"
        data-aos-delay={800}
        className="navbar navbar-expand"
        style={{ ...style, backgroundColor }}
      >
        <div className="container header">
          {/* Navbar Brand*/}
          <a className="navbar-brand navbar-Custom-style" href="/">
            {/* <img
              className="navbar-brand-regular"
              src={props.imageData}
              alt="brand-logo"
              style={{ height: 43, width: 150 }}
            /> */}
            <img
              className="navbar-brand-sticky"
              src="/img/imgpsh_fullsize_anim.png"
              alt="sticky brand-logo sticky-header-style"
              style={{ height: 43, width: 150, color: "#6610f2" }}
            />
          </a>
          <div className="ml-auto" />
          {/* Navbar */}
          <ul className="navbar-nav items">
            <li className="nav-item">
              <a href="/" className="nav-link">
                Home
              </a>
            </li>
            {navigationMenu?.map((val, i) => (
              <li className="nav-item dropdown" key={i}>
                <a className="nav-link" href="/#">
                  {val?.name}
                  {val?.menu && <i className="fas fa-angle-down ml-1" />}
                </a>
                {val?.menu && (
                  <ul className="dropdown-menu">
                    {val?.menu?.map((vall, ind) => (
                      <li className="nav-item dropdown" key={ind}>
                        <a className="nav-link" href="/#">
                          {vall.menuName}
                          {vall.subMenu && (
                            <i className="fas fa-angle-right ml-1" />
                          )}
                        </a>
                        {vall?.subMenu ? (
                          <ul className="dropdown-menu">
                            {vall?.subMenu?.map((value, index) => (
                              <li className="nav-item dropdown" key={index}>
                                <a className="nav-link" href={value?.link}>
                                  {value?.subMenuName}
                                </a>
                              </li>
                            ))}
                          </ul>
                        ) : null}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
            <li className="nav-item dropdown">
              <a className="nav-link" href="/#">
                Pricing
                <i className="fas fa-angle-down ml-1" />
              </a>
              <ul className="dropdown-menu">
                <li className="nav-item dropdown">
                  <a href="/#" className="nav-link">
                    SEO Packages
                  </a>
                  <ul className="dropdown-menu">
                    <li className="nav-item">
                      <a
                        href="/pricing/seo-packages/pocket-friendly-seo-packages"
                        className="nav-link"
                      >
                        Pocket-Friendly SEO Packages
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/pricing/seo-pricing" className="nav-link">
                        Standard SEO Packages
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="/pricing/seo-packages/corporate-seo-packages"
                        className="nav-link"
                      >
                        Corporate SEO Packages{" "}
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link" href="/pricing/smo-pricing">
                    SMO Packages
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/pricing/ppc-packages" className="nav-link">
                    PPC Packages
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/pricing/amazon-ads-services" className="nav-link">
                    Amazon Ads
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/pricing/web-packages" className="nav-link">
                    Web Packages
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a href="/aaban-portfolio" className="nav-link">
                Portfolio
              </a>
              <ul className="dropdown-menu">
                <li className="nav-item dropdown">
                  <a href="/#" className="nav-link">
                    App
                  </a>
                  <ul className="dropdown-menu">
                    <li className="nav-item">
                      <a href="/food-delivery-application" className="nav-link">
                        Food Delivery
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/real-estate-application" className="nav-link">
                        Real Estate
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a
                    href="/aaban-portfolio/seo-case-studies"
                    className="nav-link"
                  >
                    SEO
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/" className="nav-link">
                    Website{" "}
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a href="/about-us" className="nav-link">
                About Us
              </a>
            </li>
            <li className="nav-item">
              {/* <a href="/testimonial" className="nav-link">Testimonial</a> */}
            </li>
            <li className="nav-item">
              <a href="/blog" className="nav-link">
                Blog
              </a>
            </li>
            <li className="nav-item">
              <a href="/contact" className="nav-link">
                Contact Us
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/free-consultation"
                className="nav-link btn btn-bordered-grey "
              >
                Free Consultation
              </a>
            </li>
          </ul>
          {/* Navbar Toggler */}
          <ul className="navbar-nav toggle">
            <li className="nav-item" onClick={() => setIsOpen(!isOpen)}>
              <a
                href="/#"
                className="nav-link"
                data-toggle="modal"
                data-target="#menu"
              >
                <i className="fas fa-bars toggle-icon m-0" />
              </a>
            </li>
          </ul>

          {isOpen && <ModalMenu closeModal={() => setIsOpen(false)} />}
        </div>
      </nav>

      {/* Animated Call Icon CSS */}
      <style jsx>{`
        @keyframes ring {
          0% {
            transform: rotate(0deg);
          }
          10% {
            transform: rotate(15deg);
          }
          20% {
            transform: rotate(-15deg);
          }
          30% {
            transform: rotate(10deg);
          }
          40% {
            transform: rotate(-10deg);
          }
          50% {
            transform: rotate(5deg);
          }
          60% {
            transform: rotate(-5deg);
          }
          100% {
            transform: rotate(0deg);
          }
        }
      `}</style>
    </header>
  );
};

export default HeaderOne;
