import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";

const SeoScoreChecker = () => {
  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    websiteUrl: "",
    userEmail: "",
  });
  const [background, setBackground] = useState("linear-gradient(to right, #5fdaff, #4be70d)");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleMouseMove = (e) => {
    // Calculate position relative to the container
    const rect = e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;

    // Update background gradient based on mouse position
    setBackground(`radial-gradient(circle at ${x}% ${y}%, #5fdaff, #4be70d)`);
  };

  const handleSubmitModal = (e) => {
    e.preventDefault();

    const { websiteUrl, userEmail } = formData;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-]*)*\/?$/;

    if (!urlPattern.test(websiteUrl)) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Please enter a valid website URL.",
      });
      return;
    }

    if (!emailPattern.test(userEmail)) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Please enter a valid email address.",
      });
      return;
    }

    emailjs
      .sendForm("service_56xelui", "template_38c1qv8", formRef.current, "cpTPA4zZLmKmwE9nt")
      .then(
        (result) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Your query has been successfully sent. Thank you!",
          });
          formRef.current.reset();
          setFormData({
            websiteUrl: "",
            userEmail: "",
          });
        },
        (error) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "There was an error sending your query. Please try again later.",
          });
        }
      );
  };

  return (
    <>
     <style>
  {`
    /* Hover effect for the submit button */
    .button:hover {
      background-color: #e65c00;
      transform: scale(1.05);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
    }

    /* Responsive styles for tablet and mobile screens */
    @media (max-width: 768px) {
      .seo-image {
        display: none; /* Hide images for tablet and mobile screens */
      }

      .input-container {
        flex-direction: column; /* Stack inputs and button vertically */
        gap: 15px;
        align-items: center; /* Center inputs and button */
      }

      .input-container input {
        width: 90%; /* Adjust input width for tablet and mobile */
        max-width: 400px; /* Prevent inputs from being too wide */
      }

      .input-container button {
        width: 90%; /* Adjust button width for tablet and mobile */
        max-width: 200px; /* Prevent button from stretching too wide */
      }

      /* Center the container on smaller screens */
      .container {
        padding: 10px;
        text-align: center;
      }

      /* Font size adjustments for smaller screens */
      .text {
        font-size: 16px;
      }

      .heading {
        font-size: 20px;
      }
    }
  `}
     </style>

      <div
        style={{ ...styles.banner, background }}
        onMouseMove={handleMouseMove}
      >
        <form ref={formRef} onSubmit={handleSubmitModal}>
          <div className="container" style={styles.flexRow}>
            <img
              src="/img/runningtogeter.png"
              alt="SEO"
              className="seo-image"
              style={styles.imageLarge}
            />
            <div style={styles.container}>
              <p style={styles.text}>See how optimized your website is for an exact keyword — for FREE!</p>
              <p style={styles.heading}>WHAT'S YOUR WEBSITE SEO SCORE?</p>
              <div className="input-container" style={styles.inputContainer}>
                <input
                  type="text"
                  name="websiteUrl"
                  placeholder="Enter your website URL"
                  style={styles.input}
                  value={formData.websiteUrl}
                  onChange={handleChange}
                />
                <input
                  type="email"
                  name="userEmail"
                  placeholder="Enter your email"
                  style={styles.input}
                  value={formData.userEmail}
                  onChange={handleChange}
                />
                <button type="submit" style={styles.button} className="button">Submit</button>
              </div>
              <p style={styles.privacyText}>
                Please check our <a href="/privacy-policy" style={styles.link}>Privacy Policy</a> to find out how we manage and protect your data.
              </p>
            </div>
            <img
              src="/img/manrunning.png"
              alt="SEO"
              className="seo-image"
              style={styles.imageSmall}
            />
          </div>
        </form>
      </div>
    </>
  );
};

const styles = {
  banner: {
    padding: "40px 20px",
    transition: "background 0.2s ease",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    maxWidth: "600px",
    textAlign: "center",
  },
  text: {
    fontSize: "18px",
    fontWeight: "400",
    marginBottom: "10px",
    color: "#333",
  },
  heading: {
    fontSize: "24px",
    fontWeight: "600",
    marginBottom: "20px",
    color: "#222",
  },
  inputContainer: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginBottom: "20px",
  },
  input: {
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ddd",
    fontSize: "16px",
    outline: "none",
    flex: "1",
    width: "80%",
  },
  button: {
    backgroundColor: "#ff6600",
    color: "#fff",
    padding: "12px 24px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    transition: "background 0.3s ease",
  },
  privacyText: {
    fontSize: "14px",
    marginTop: "15px",
    color: "#444",
  },
  link: {
    color: "#fff",
    textDecoration: "underline",
    cursor: "pointer",
  },
  imageLarge: {
    width: "250px",
    height: "250px",
  },
  imageSmall: {
    width: "200px",
    height: "200px",
  },
};

export default SeoScoreChecker;
