import React from 'react';

const BreadcrumbForFooterLocations = (props) => {
    const { image, children } = props;

    const sectionStyle = image ? {
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    } : {};

    const isImage = image ? "isImage-style" : "";

    return (
        <section className={`section breadcrumb-area overlay-dark ${isImage} d-flex align-items-center`} style={sectionStyle}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {children}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BreadcrumbForFooterLocations;
