import React, { useRef, useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import HeaderOne from "../components/Header/HeaderOne";
import Imageframe from "../components/ImageFrame/ImageFrame";
import HeroOne from "../components/Hero/HeroOne";
import PromoOne from "../components/Promo/PromoOne";
import ContentOne from "../components/Content/ContentOne";
import ReviewOne from "../components/Reviews/ReviewOne";
import FooterOne from "../components/Footer/FooterOne";
import Banner2 from "../components/Banners/Banner2";
import NewsCard from "../components/Card/NewsCard";
import NotchServices from "../components/NotchServices/NotchServices";
import PricingForHomePage from "../components/Pricing/PricingForHomePage";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import { MdCancel } from "react-icons/md";
import DisplayLocationBanner from "../components/Banners/DisplayLocationBanner";
import WorkProcess from "../components/FlowCharts/WorkProcess";
import FAQAccordion from "../components/FAQ/FAQAccordion";
import SeoScoreChecker from "../components/SeoScoreChecker/SeoScoreChecker";
import GoogleReviewsWidget from "../components/GoogleReviews/GoogleReviewsWidget";
import ClientVideo from "../components/ClientVideo/ClientVideo";


const ThemeOne = () => {
  const [showModal, setShowModal] = useState(true);
  const [showChatbot, setShowChatbot] = useState(false);
  const [step, setStep] = useState([]);
  const formRef = useRef(null);
  const modalRef = useRef(null);
  const [formData, setFormData] = useState({
    userName: "",
    userEmail: "",
    websiteUrl: "",
    user_mobile:"",
  });

  const handleClose = () => setShowModal(false);

  useEffect(() => {
    const chatHistory = JSON.parse(localStorage.getItem("chatHistory")) || [];
    setStep(chatHistory);
  }, []);

  useEffect(() => {
    setShowModal(true);
  }, []);
  
  const handleSubmitModal = (e) => {
    e.preventDefault();
  
    const form = modalRef.current;
    const userName = form.user_name.value.trim();
    const userEmail = form.user_email.value.trim();
    const userMobile = form.user_mobile.value.trim();
  
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^[0-9]{10}$/;
  
    if (!userName) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Please enter your name.",
      });
      return;
    }
  
    if (!emailPattern.test(userEmail)) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Please enter a valid email address.",
      });
      return;
    }
  
    if (!phonePattern.test(userMobile)) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Please enter a valid 10-digit mobile number.",
      });
      return;
    }
    emailjs
      .sendForm(
        "service_56xelui",
        "template_38c1qv8",
        modalRef.current,
        "cpTPA4zZLmKmwE9nt"
      )
      .then(
        (result) => {
          console.log(result);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Your query has been successfully sent. Thank you!",
            showConfirmButton: true,
          });
          form.reset();
          setFormData({
            userName: "",
            userEmail: "",
            websiteUrl: "",
            user_mobile: "",
          });
          setShowModal(false);
        },
        (error) => {
          console.log(error.text);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "There was an error sending your query. Please try again later.",
          });
        }
      );
  };

  return (
    <div>
      <div className="main overflow-hidden">
        <HeaderOne imageData={"/img/aaban-text-logo.png"} />
        <HeroOne />
        <DisplayLocationBanner/>
        <PromoOne />
        {/* <Imageframe /> */}
        <NotchServices getFreeProposalModal={() => setShowModal(true)}/>
        {/* <ClientVideo/> */}
        <Banner2 textColor="text-black" />
        <PricingForHomePage />
        {/* <WorkProcess/> */}
       <Modal show={showModal} onHide={handleClose} centered size="lg">
          <Modal.Header style={{ backgroundColor: "#973de4", color: "#fff" }}>
            <Modal.Title style={{ margin: "0 auto" }}>
              Let's Start and Grow your Business 
            </Modal.Title>
            <MdCancel size={28} onClick={handleClose} cursor={"pointer"} />
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: "#f8f9fa",
              minHeight: "400px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div className="d-flex col-12 model-form-style">
              <div className="col-md-6">
                <p style={{ color: "#973de4" }}>
                  <i className="fa-solid fa-user-plus"></i> 100+ Happy Clients
                </p>
                <hr style={{ borderTop: "1px solid #973de4" }} />
                <p style={{ color: "#973de4" }}>
                  <i className="fa-solid fa-phone-volume"></i> 9,78,00+ Calls
                  Generated
                </p>
                <hr style={{ borderTop: "1px solid #973de4" }} />
                <p style={{ color: "#973de4" }}>
                  <i className="fa-solid fa-user-plus"></i> $1,80,000+ Revenue
                  Generated
                </p>
                <hr style={{ borderTop: "1px solid #973de4" }} />
                <p style={{ color: "black" }}>
                  By submitting my contact details here, I consent to receive
                  emails & text messages from Aaban Solution.
                </p>
              </div>
              <form
                className="col-md-6"
                style={{ marginLeft: "5%" }}
                ref={modalRef}
                onSubmit={handleSubmitModal}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your website URL"
                  name="user_name"
                  style={{ marginBottom: "20px", height: "50px" }}
                />
                <input
                  type="email"
                  className="form-control"
                  placeholder="User Email"
                  name="user_email"
                  style={{ marginBottom: "20px", height: "50px" }}
                />
                {/* <input
                  type="text"
                  className="form-control"
                  placeholder="Mobile Number"
                  name="user_mobile"
                  maxLength='10'
                  style={{ marginBottom: "20px", height: "50px" }}
                />
                <input
                  className="form-control"
                  placeholder="Message"
                  name="message"
                  style={{ marginBottom: "20px", height: "100px" }}
                /> */}
                <Button
                  variant="primary"
                  type="submit"
                  style={{ backgroundColor: "#973de4", borderColor: "#973de4" }}
                >
                  Submit
                </Button>
              </form>
            </div>
          </Modal.Body>
        </Modal>
        <ContentOne getFreeProposalModal={() => setShowModal(true)} />

        <div
          className=""
          style={{
            width: "100%",
            backgroundImage: "url('/img/dotted.png')",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
            border: "1px solid transparent",
          }}
        >
          <section className="container text-center px-0">
            <h3 className="mt-5 mb-3 pt-3">Our News </h3>
            <div
              className="d-flex news-card "
              style={{
                gap: "30px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <NewsCard
                image="lsi-keywords"
                question="LSI Keywords: How do related words improve SEO?"
                date="February 22nd, 2024"
                disc="Remember when websites were stuffed with keywords, trying to reach the top of search results? Gone are those SEO tactics"
              />
              <NewsCard
                image="SEO-news"
                question="Off Page SEO Essentials: A beginner’s guide to promote your website"
                date="February 22nd, 2024"
                disc="Suppose you have bought a vintage house with a huge living room, spacious bedrooms, a vast garden, etc. However, no"
              />
              <NewsCard
                image="important-fector-sco"
                question="Essential On Page SEO Factors You Must Know"
                date="February 22nd, 2024"
                disc="Today, most people expect to find whatever they search at the top of Google search results. If your website is"
              />
            </div>
          </section>
        </div>

        <ReviewOne />

        <SeoScoreChecker />
        
        <FAQAccordion />
        <GoogleReviewsWidget />
        <FooterOne />
      </div>
    </div>
  );
};

export default ThemeOne;
