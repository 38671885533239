import React from "react";
import HeaderOne from "../Header/HeaderOne";
import BreadcrumbFour from "../Breadcrumb/BreadcrumbFour";
import blogData from "../../data/blogitem.json"; // Import your JSON file
import "./BlogItem.css";
import ReviewOne from "../Reviews/ReviewOne";
import FooterOne from "../Footer/FooterOne";
import BreadcrumbOne from "../Breadcrumb/BreadcrumbOne";
import BreadcrumbForSEOmarket from "../Breadcrumb/BreadcrumbForSEOmarket";

const BlogItem = ({ pageName }) => {
  // Get content from JSON based on pageName
  const content = blogData[pageName]?.content || "<p>Content not found.</p>";

  return (
    <>
    <div style={{backgroundColor: "#f5f5f5"}}>
      <HeaderOne imageData="/img/aaban-text-logo.png" />
      <BreadcrumbForSEOmarket path="Blog" title="Blog" />
      <div
        style={{
            background: "linear-gradient(to right,rgb(95, 218, 255),rgb(75, 231, 13))", 
            padding: "20px", // Optional padding
            textAlign: "center" // Center content
        }}
        >
    <h1 className="text-center pt-5 pb-5">Digital marketing agency in New York</h1>
    </div>

      {/* Render the blog content */}
      <div className="container pt-5 pb-5">
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
     <ReviewOne />
     <FooterOne />
     </>
  );
};

export default BlogItem;
