import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ThemeOne from "../themes/theme-one";
import ServicesNav from "../pages/Services";
import Services from "../components/InnerPages/Services/Services";
import ServicesNav1 from "../pages/Services1";
import ServicesNav2 from "../pages/Services2";
import Market from "../pages/Market";
import PricingSection from "../pages/PricingSection";
import Portfolio from "../pages/Portfolio";
import Aboutus from "../pages/Aboutus";
import Testimonial from "../pages/Testimonial";
import Blog from "../components/Blogs/Blogs";
import Contactus from "../pages/Contactus";
import FreeConsultaion from "../pages/FreeConsultaion";
import AppSection from "../pages/AppSection";
import WebDevelopment from "../components/Web-Development/WebDevelopment";
import SEOmarket from "../components/SEOmarket/SEOmarket";
import TermsOfService from "../components/FooterLinks/TermsOfService";
import PrivacyPolicy from "../components/FooterLinks/PrivacyPolicy";
import SeoCaseStudies from "../components/PortfolioSubItems/SeoCaseStudies";
import NotFoundPage from "../components/Page404/404page";
import BlogItem from "../components/Blogs/BlogItem";
import FooterLocations from "../components/FooterLocations/FooterLocations";

// Correct the component name to MyRoutes
function MyRoutes() {
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/" element={<ThemeOne />} />

          <Route
            path="/digital-marketing/seo-services-usa"
            element={<ServicesNav pageName="seo-services-usa" />}
          />
      

          <Route
            path="/digital-marketing/seo-services-usa"
            element={<ServicesNav pageName="seo-services-usa" />}
          />

          <Route
            path="/digital-marketing/app-store-optimization-services"
            element={<ServicesNav pageName="app-store-optimization-services" />}
          />

          <Route
            path="digital-marketing/online-reputation-management-services"
            element={
              <ServicesNav pageName="online-reputation-management-services" />
            }
          />

          <Route
            path="digital-marketing/affordable-seo-services-for-small-business"
            element={
              <ServicesNav2 pageName="affordable-seo-services-for-small-business" />
            }
          />

          <Route
            path="digital-marketing/ecommerce-seo-services"
            element={<ServicesNav2 pageName="ecommerce-seo-services" />}
          />

          <Route
            path="digital-marketing/local-seo-services"
            element={<ServicesNav2 pageName="local-seo-services" />}
          />

          {/****** example routes from link on our services  *****/}

          {/* <Route
            path="seo-market/aviation-seo-services/digital-marketing/seo-services-usa"
            element={<ServicesNav2 pageName="local-seo-services" />}
          /> */}

          <Route
            path="/digital-marketing/seo-reseller-white-label-services"
            element={
              <ServicesNav pageName="seo-reseller-white-label-services" />
            }
          />

          <Route
            path="/digital-marketing/youtube-seo-services"
            element={<ServicesNav pageName="youtube-seo-services" />}
          />

          <Route
            path="/digital-marketing/pay-per-click-ppc"
            element={<ServicesNav pageName="pay-per-click-ppc" />}
          />

          <Route
            path="/digital-marketing/facebook-advertising-services"
            element={<ServicesNav pageName="facebook-advertising-services" />}
          />

          <Route
            path="/digital-marketing/instagram-advertising"
            element={<ServicesNav pageName="instagram-advertising" />}
          />

          <Route
            path="/digital-marketing/linkedin-advertising-services"
            element={<ServicesNav pageName="linkedin-advertising-services" />}
          />

          <Route
            path="/digital-marketing/x-twitter-advertising-services"
            element={<ServicesNav pageName="x-twitter-advertising-services" />}
          />

          <Route
            path="/digital-marketing/content-marketing-services"
            element={<ServicesNav pageName="content-marketing-services" />}
          />

          <Route
            path="/digital-marketing/pinterest-advertising-agency"
            element={<ServicesNav pageName="pinterest-advertising-agency" />}
          />

          <Route
            path="/digital-marketing/google-business-profile-optimization"
            element={
              <ServicesNav pageName="google-business-profile-optimization" />
            }
          />

          <Route
            path="/digital-marketing/ecommerce-website-design"
            element={<ServicesNav pageName="ecommerce-website-design" />}
          />

          <Route
            path="/digital-marketing/magento-website-development"
            element={<ServicesNav pageName="magento-website-development" />}
          />

          <Route
            path="/digital-marketing/joomla-website-design"
            element={<ServicesNav pageName="joomla-website-design" />}
          />

          <Route
            path="/digital-marketing/mobile-app-development"
            element={<ServicesNav pageName="mobile-app-development" />}
          />

          <Route
            path="/digital-marketing/payment-gateway-integration"
            element={<ServicesNav pageName="payment-gateway-integration" />}
          />

          <Route
            path="/digital-marketing/shopify-website-design"
            element={<ServicesNav pageName="shopify-website-design" />}
          />

          <Route
            path="/digital-marketing/woocommerce-web-design"
            element={<ServicesNav pageName="woocommerce-web-design" />}
          />

          <Route
            path="/digital-marketing/wordpress-website-design"
            element={<ServicesNav pageName="wordpress-website-design" />}
          />

          <Route
            path="/digital-marketing/website-redesign-service"
            element={<ServicesNav pageName="website-redesign-service" />}
          />

          <Route
            path="/seo-market"
            element={<SEOmarket pageName="seo-market" />}
          />

          <Route
            path="/seo-market/aviation-seo-services"
            element={<Market pageName="aviation-seo-services" />}
          />

          <Route
            path="/seo-market/automotive-seo-services"
            element={<Market pageName="automotive-seo-services" />}
          />

          <Route
            path="/seo-market/home-remodeling-seo-services"
            element={<Market pageName="home-remodeling-seo-services" />}
          />

          <Route
            path="/seo-market/cleaning-seo-services"
            element={<Market pageName="cleaning-seo-services" />}
          />

          <Route
            path="/seo-market/construction"
            element={<Market pageName="construction" />}
          />

          <Route
            path="/seo-market/insurance-marketing"
            element={<Market pageName="insurance-marketing" />}
          />

          <Route
            path="/seo-market/seo-for-furniture-stores"
            element={<Market pageName="seo-for-furniture-stores" />}
          />

          <Route
            path="/seo-market/entertainment-marketing"
            element={<Market pageName="entertainment-marketing" />}
          />

          <Route
            path="/seo-market/logistic-seo"
            element={<Market pageName="logistic-seo" />}
          />

          <Route
            path="/seo-market/real-estate-seo"
            element={<Market pageName="real-estate-seo" />}
          />

          <Route
            path="/seo-market/restaurant-marketing"
            element={<Market pageName="restaurant-marketing" />}
          />

          <Route
            path="/seo-market/agriculture-seo-agency"
            element={<Market pageName="agriculture-seo-agency" />}
          />

          <Route
            path="/seo-market/fitness-seo-services"
            element={<Market pageName="fitness-seo-services" />}
          />

          <Route
            path="/seo-market/seo-for-kitchen-designers"
            element={<Market pageName="seo-for-kitchen-designers" />}
          />

          <Route
            path="/seo-market/healthcare-seo-services"
            element={<Market pageName="healthcare-seo-services" />}
          />

          <Route
            path="/seo-market/massage-therapists"
            element={<Market pageName="massage-therapists" />}
          />

          <Route
            path="/seo-market/catering-seo"
            element={<Market pageName="catering-seo" />}
          />

          <Route
            path="/seo-market/yoga-marketing"
            element={<Market pageName="yoga-marketing" />}
          />

          <Route
            path="/seo-market/dental-seo"
            element={<Market pageName="dental-seo" />}
          />

          <Route
            path="/seo-market/pharma-seo"
            element={<Market pageName="pharma-seo" />}
          />

          <Route
            path="/seo-market/daycare-seo"
            element={<Market pageName="daycare-seo" />}
          />

          <Route
            path="/seo-market/hvac-digital-marketing"
            element={<Market pageName="hvac-digital-marketing" />}
          />

          <Route
            path="/seo-market/seo-for-contractors"
            element={<Market pageName="seo-for-contractors" />}
          />

          <Route
            path="/seo-market/fire-protection"
            element={<Market pageName="fire-protection" />}
          />

          <Route
            path="/seo-market/financial-seo"
            element={<Market pageName="financial-seo" />}
          />

          <Route
            path="/seo-market/pest-control-seo"
            element={<Market pageName="pest-control-seo" />}
          />

          <Route
            path="/seo-market/seo-for-salons"
            element={<Market pageName="seo-for-salons" />}
          />

          <Route
            path="/seo-market/veterinary-seo"
            element={<Market pageName="veterinary-seo" />}
          />

          <Route
            path="/seo-market/appliance-repair"
            element={<Market pageName="appliance-repair" />}
          />

          <Route
            path="/seo-market/plumbing-seo"
            element={<Market pageName="plumbing-seo" />}
          />

          <Route
            path="/seo-market/cannabis-seo"
            element={<Market pageName="cannabis-seo" />}
          />

          <Route
            path="/seo-market/education-marketing"
            element={<Market pageName="education-marketing" />}
          />

          <Route
            path="/seo-market/jewelry-marketing"
            element={<Market pageName="jewelry-marketing" />}
          />

          <Route
            path="/seo-market/retail-seo"
            element={<Market pageName="retail-seo" />}
          />

          <Route
            path="/seo-market/travel-seo"
            element={<Market pageName="travel-seo" />}
          />

          <Route
            path="/seo-market/hospitality-seo"
            element={<Market pageName="hospitality-seo" />}
          />

          <Route
            path="/seo-market/tree-services"
            element={<Market pageName="tree-services" />}
          />

          <Route
            path="/seo-market/seo-for-photographers"
            element={<Market pageName="seo-for-photographers" />}
          />

          <Route
            path="/seo-market/packers-movers"
            element={<Market pageName="packers-movers" />}
          />

          <Route
            path="/seo-market/lawyer-seo"
            element={<Market pageName="lawyer-seo" />}
          />

          <Route
            path="/pricing/seo-pricing"
            element={<PricingSection pageName="seo-pricing" />}
          />

          <Route
            path="/pricing/smo-pricing"
            element={<PricingSection pageName="smo-pricing" />}
          />

          <Route
            path="/pricing/ppc-packages"
            element={<PricingSection pageName="ppc-packages" />}
          />

          <Route
            path="/pricing/amazon-ads-services"
            element={<PricingSection pageName="amazon-ads-services" />}
          />

          <Route
            path="/pricing/web-packages"
            element={<PricingSection pageName="web-packages" />}
          />

          <Route
            path="/pricing/maintenance"
            element={<PricingSection pageName="maintenance" />}
          />

          <Route
            path="/pricing/smo-packages/one-time-structural-set-up"
            element={<PricingSection pageName="one-time-structural-set-up" />}
          />

          <Route
            path="/pricing/seo-packages/pocket-friendly-seo-packages"
            element={<PricingSection pageName="pocket-friendly-seo-packages" />}
          />

          <Route
            path="/pricing/seo-packages/corporate-seo-packages"
            element={<PricingSection pageName="corporate-seo-packages" />}
          />

          <Route
            path="/aaban-portfolio"
            element={<Portfolio pageName="aaban-portfolio" />}
          />
          <Route
            path="/aaban-portfolio/seo-case-studies"
            element={<SeoCaseStudies pageName="seo-case-studies" />}
          />

          <Route path="about-us" element={<Aboutus pageName="about-us" />} />

          <Route
            path="testimonial"
            element={<Testimonial pageName="testimonial" />}
          />

          <Route path="blog" element={<Blog pageName="blog" />} />

          <Route path="/blog/digital-marketing-agency-in-new-york" element={<BlogItem pageName="digital-marketing-agency-in-new-york" />} />

          <Route path="contact" element={<Contactus pageName="contact" />} />

          <Route
            path="free-consultation"
            element={<FreeConsultaion pageName="free-consultation" />}
          />

          <Route
            path="/food-delivery-application"
            element={<AppSection pageName="food-delivery-application" />}
          />

          <Route
            path="/real-estate-application"
            element={<AppSection pageName="real-estate-application" />}
          />
          <Route
            path="/digital-marketing"
            element={<WebDevelopment pageName="digital-marketing" />}
          />

          <Route
            path="/web-development"
            element={<WebDevelopment pageName="web-development" />}
          />
          <Route
            path="/digital-advertising"
            element={<WebDevelopment pageName="digital-advertising" />}
          />

          <Route
            path="/terms-of-service"
            element={<TermsOfService pageName="terms-of-service" />}
          />

          <Route
            path="/privacy-policy"
            element={<PrivacyPolicy pageName="privacy-policy" />}
          />

          <Route
            path="/location/:city"
            element={<FooterLocations/>}
          />

          <Route path="/services" element={<Services />} />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default MyRoutes;
