import React, { useState } from "react";
import HeaderOne from "../Header/HeaderOne";
import BreadcrumbThree from "../Breadcrumb/BreadcrumbThree";
import FooterOne from "../Footer/FooterOne";
import { FaPinterest } from "react-icons/fa";
import { BiLogoDribbble } from "react-icons/bi";
import { FaSquareBehance } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { FaSkype } from "react-icons/fa";
import { FacebookIcon, LinkedinIcon, WhatsappIcon, XIcon } from "react-share";

const PrivacyPolicy = () => {

    const [hovered, setHovered] = useState(null);
    
      const socialIcons = [
        {
          link: "https://www.facebook.com/AabanSolutions/",
          icon: <FacebookIcon size={23} round={true} />,
        },
        {
          link: "https://x.com/AabanSolutions_",
          icon: <XIcon size={23} round={true} />,
        },
        {
          link: "https://www.linkedin.com/company/aaban-solutions/",
          icon: <LinkedinIcon size={23} round={true} />,
        },
        {
          link: "https://in.pinterest.com/aabansolutions313/",
          icon: <FaPinterest color="#e60023" size={23} />,
        },
        { link: "#", icon: <BiLogoDribbble size={23} color="#ea4c89" /> },
        {
          link: "https://www.behance.net/aaban/",
          icon: <FaSquareBehance size={23} color="#053eff" />,
        },
        { link: "#", icon: <FaYoutube size={23} color="#e71717" /> },
        { link: "#", icon: <FaSkype size={23} color="#16b6fb" /> },
      ];

  return (
    <>
      <HeaderOne imageData={"/img/aaban-text-logo.png"} />
      <BreadcrumbThree
        path={"PRIVACY POLICY"}
        title={"PRIVACY POLICY"}
        image={"/img/pexels-pixabay-258160.jpg"}
      />

      <div style={{ borderBottom: "1px solid grey" }}>
        <section className="container my-5">
          <div className="my-2">
            <h3 className="mt-4">Privacy Policy</h3>
            <p className="mt-3">
              Our company and its partners are highly committed to protecting
              the privacy of your data. Our Privacy Policy governs our data
              usage patterns, data collection, and data processing. Moreover, we
              have described the way you can use and access your personal data.
              Make sure that you acknowledge our Privacy Policy before visiting
              our site.
            </p>
            <p className="mt-3">
              We gather data while obtaining feedback about our services. In
              some cases, you submit your personal details directly while
              filling in the contact form.
            </p>
            <p className="mt-3">
              Our company also collects data from some third parties. Our team
              protects third-party data based on our privacy policies. We rely
              on third-party sources, including
            </p>
            <ul className="mt-2">
              <li>
                • Our service providers helping us to identify a location and
                customize the products
              </li>
              <li>• Partners for offering co-branded services</li>
              <li>• Public sources like open government databases</li>
            </ul>
            <p className="mt-3">
              However, when we request you to submit data, you have the right to
              decline it.
            </p>
          </div>

          <div className="my-2">
            <h4 className="mt-4">Type of data that we collect</h4>
            <p className="mt-3">Your name, mobile number, and email address</p>
            <p className="mt-3">
              Demographic details like your preferred language and country
            </p>
            <p className="mt-3">
              Website information- We try to know how you have interacted with
              your website. Moreover, we need data related to your IP address
              and your chosen network connected to our site.
            </p>
            <p className="mt-3">
              Our team also takes some steps to gather data about issues you
              have encountered while using our website. Based on your browsers
              settings, error reports may include data like the type of problems
              and software errors.
            </p>
          </div>

          <div className="my-2">
            <h4 className="mt-4">Why does our team collect your data?</h4>
            <p className="mt-3">
              There are some reasons for gathering your data-
            </p>
            <ul className="mt-2">
              <li>
                • To send messages like promotional messages and advertising
                messages
              </li>
              <li>• Analyze statistical data about website visitors</li>
              <li>• Troubleshoot technical problems of our website</li>
              <li>
                • Provide statistical information to third parties without
                revealing your personal details
              </li>
              <li>
                • Send information about important updates of our terms and
                conditions
              </li>
              <li>• Provide better services in the future</li>
            </ul>
            <p className="mt-3">
              We can post customer comments and testimonials on our site, and
              they may contain some personal details. We do not do it without
              our clients’ consent. We will send an email to get your consent.
            </p>
          </div>

          <div className="my-2">
            <h4 className="mt-4">Secure your personal data</h4>
            <p className="mt-3">
              We apply different security measures to protect your personal
              details from unauthorized use and access by third parties. Our
              company has invested in procedural and technological safeguards to
              avoid data leaks. Our system stores your personal data in a highly
              secure and controlled environment. You do not need to be concerned
              about our secure database.
            </p>
          </div>

          <div className="my-2">
            <h4 className="mt-4">Retaining your personal data</h4>
            <p className="mt-3">
              The overall duration for which our company retains and stores your
              personal data can vary with the nature of your data. We will
              delete your data from our database. However, in some cases, we can
              make your data anonymous to prevent any risks. Our company may
              retain your basic personal details shared by you to continue our
              legitimate business. We use your email address to send your
              newsletters. But, to avoid receiving these newsletters, you can
              unsubscribe from us.
            </p>
            <p className="mt-3">
              We can modify different parts of the content on our Privacy Policy
              page at any time. Thus, you must read our page regularly to know
              about the updated policies.
            </p>
          </div>

          <div className="my-4" style={{ paddingTop: "30px" }}>
            <h4 className="mt-4">Are you ready to work with us?</h4>
            <p className="mt-3">Connect with social media</p>
            <ul
              className="list-inline pt-2 d-flex flex-wrap"
              style={{ gap: "15px", width: "80%" }}
            >
              {socialIcons.map((item, index) => (
                <li key={index}>
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    onMouseEnter={() => setHovered(index)}
                    onMouseLeave={() => setHovered(null)}
                    style={{
                      display: "inline-block",
                      transform: hovered === index ? "scale(1.3)" : "scale(1)",
                      transition: "transform 0.2s ease-in-out",
                    }}
                  >
                    {item.icon}
                  </a>
                </li>
              ))}
            </ul>
          </div>         

        </section>
      </div>
      <FooterOne />
    </>
  );
};

export default PrivacyPolicy;
