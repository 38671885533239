import React, { useRef, useState } from "react";

const Banner2 = (props) => {
  const { textColor } = props;
  const sliderRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const images = [
    { src: "/img/Google-review.jpg", alt: "Google Review", link: "https://www.google.com/search?q=aaban+solutions" },
    { src: "/img/hospot.jpg", alt: "Hubspot" },
    { src: "/img/bbb-logo.png", alt: "bbb logo" },
    { src: "/img/cluch.jpg", alt: "Clutch", link: "https://clutch.co/profile/aaban-solutions#highlights" },
    { src: "/img/sitejabber3.png", alt: "sitejabber", link: "https://www.sitejabber.com/users/aabans" },
    { src: "/img/Trustpilot.jpg", alt: "Trustpilot", link: "https://trustpilot.com/review/aabansolutions.com" },
  ];

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const handleMouseLeave = () => setIsDragging(false);

  const handleMouseUp = () => setIsDragging(false);

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = x - startX; // Calculate how far the mouse has moved
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <div style={{ backgroundColor: "#f1f1f1", padding: "2rem" }}>
      {/* Heading Section */}
      <div style={{ textAlign: "center", marginBottom: "1.5rem" }}>
        <h3 className={`${textColor ? textColor : "text-dark"}`} style={{ fontWeight: 600 }}>
          Trusted & supported by
        </h3>
      </div>

      {/* Sliding Image Section */}
      <div
        ref={sliderRef}
        style={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: isDragging ? "grabbing" : "grab",
        }}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        <div style={{ display: "flex", animation: "slide 15s linear infinite" }}>
          {images.map((img, index) => (
            <div
              className="card support-box-style"
              key={index}
              style={{
                maxWidth: "11rem",
                borderRadius: "8%",
                minWidth: "150px",
                margin: "0 1rem",
              }}
            >
              <div
                className="card-body"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "1rem",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  borderRadius: "8%",
                }}
              >
                {img.link ? (
                  <a href={img.link} target="_blank" rel="noopener noreferrer">
                    <img src={img.src} alt={img.alt} style={{ maxWidth: "100%" }} />
                  </a>
                ) : (
                  <img src={img.src} alt={img.alt} style={{ maxWidth: "100%" }} />
                )}
              </div>
            </div>
          ))}

          {/* Clone the images to create an infinite loop effect */}
          {images.map((img, index) => (
            <div
              className="card support-box-style"
              key={`clone-${index}`}
              style={{
                maxWidth: "11rem",
                borderRadius: "8%",
                minWidth: "150px",
                margin: "0 1rem",
              }}
            >
              <div
                className="card-body"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "1rem",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  borderRadius: "8%",
                }}
              >
                {img.link ? (
                  <a href={img.link} target="_blank" rel="noopener noreferrer">
                    <img src={img.src} alt={img.alt} style={{ maxWidth: "100%" }} />
                  </a>
                ) : (
                  <img src={img.src} alt={img.alt} style={{ maxWidth: "100%" }} />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Embedded styles for keyframes animation */}
      <style>{`
        @keyframes slide {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-20%);
          }
        }
      `}</style>
    </div>
  );
};

export default Banner2;
